import { DiffInformation } from "./compute-lines";

export type IgnoreRule = {
    expression: string,
    enabled: boolean,
    name: string,
    error: boolean,
}


export type FuzzResult = {
    originalText: string,
    textForCompare: string,
    placeHolderValues: string[]
}

const placeHolder = "%%%%"


export function getIgnoreRegexp(ignoreRules: IgnoreRule[]): RegExp {
    if (ignoreRules.length === 0) {
        return null
    }
    const validRules = ignoreRules.filter(it => it.enabled && !it.error);
    if (validRules.length === 0) {
        return null
    }
    const regexp = new RegExp(validRules.map(it => '(' + it.expression + ')').join("|"), "g");
    //console.log("xxx", regexp.source)
    return regexp;
}

export function fuzz(originalText: string, ignoreRegexp: RegExp): FuzzResult {

    //onsole.log("fuzz", ignoreRegexp)

    let placeHolderValues: string[] = []

    let textForCompare = originalText

    if (ignoreRegexp) {

        placeHolderValues = textForCompare.match(ignoreRegexp)

        textForCompare = textForCompare.replace(ignoreRegexp, placeHolder)
    }

    return {
        originalText,
        textForCompare,
        placeHolderValues,
    }

}

export function removeFuzz(part: DiffInformation, values: string[], index: number, fuzzPrefix = "<", fuzzSuffix = ">") {
    //console.log("before", part, values)
    if (part.value instanceof Array) {

        let array = (part.value as DiffInformation[]);

        for (let i = 0; i < array.length; i++) {
            let fuzzValue = array[i].value as string;
            while (fuzzValue.includes(placeHolder)) {
                fuzzValue = fuzzValue.replace(placeHolder, fuzzPrefix + values[index++] + fuzzSuffix);
            }

            array[i].value = fuzzValue

        }

    } else {
        let fuzzValue = part.value as string;
        while (fuzzValue.includes(placeHolder)) {
            fuzzValue = fuzzValue.replace(placeHolder, fuzzPrefix + values[index++] + fuzzSuffix);
        }
        part.value = fuzzValue
    }
    //console.log("after", part)

    return index;
}