import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import './App.css';
import { FuzzResult, IgnoreRule, removeFuzz } from './CompareEngine';
import { ComputedLineInformation, LineInformation } from './compute-lines';
import DiffViewer from './DiffViewer';
import IgnoreRulesEditor from './IgnoreRulesEditor';
import _, { Cancelable } from 'lodash';


import firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAakfbmMGFiEqVoPnwH6eHoU7Nmt4pAoUo",
  authDomain: "log-diff.firebaseapp.com",
  projectId: "log-diff",
  storageBucket: "log-diff.appspot.com",
  messagingSenderId: "355826757553",
  appId: "1:355826757553:web:c03704413a267c8da69790",
  measurementId: "G-FEYDYXH9FF"
};

try {
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  firebase.analytics();
} catch (e) {
  console.log(e)
}

const originalGood = `this is Normal Log
  2011-11-11 23:00:08,084 [_aa1bb2cc3] priority=INFO  app_name=test_app thread=ajp-nio-8009-exec-3 location=Filter line=972 _message=HttpResponseOut dye=_aa1bb2cc3 method=PUT uri=/entity/v1/1234 _respTime=203
  2011-11-11 23:00:08,079 [_aa1bb2cc3] priority=INFO  app_name=test_app thread=ajp-nio-8009-exec-3 location=MethodAspect line=918 domain=entity operation=update status=OK httpCode=200 respTime=189 retryable=false entityId=1234 externalId=0 errorCode=OK
  2011-11-11 23:00:08,078 [_aa1bb2cc3] priority=INFO  app_name=test_app thread=ajp-nio-8009-exec-3 location=Helper line=957 try sending Message=Message{entityType=Product, entityId=1234} sendMessages=true
  2011-11-11 23:00:07,882 [_aa1bb2cc3] priority=INFO  app_name=test_app thread=ajp-nio-8009-exec-3 location=Filter line=966 _message=HttpRequestIn dye=_aa1bb2cc3 method=PUT uri=/entity/v1/1234
  
`;

const originalBad = `this is Suspect Log
  2011-11-12 10:10:08,406 [_xx4yy5zz6] priority=INFO  app_name=test_app thread=ajp-nio-8009-exec-5 location=Filter line=972 _message=HttpResponseOut dye=_xx4yy5zz6 method=PUT uri=/entity/v1/2345 _respTime=205
  2011-11-12 10:10:08,399 [_xx4yy5zz6] priority=INFO  app_name=test_app thread=ajp-nio-8009-exec-5 location=MethodAspect line=918 domain=entity operation=update status=OK httpCode=200 respTime=180 retryable=false entityId=2345 externalId=0 errorCode=OK
  2011-11-12 10:10:08,391 [_xx4yy5zz6] priority=INFO  app_name=test_app thread=ajp-nio-8009-exec-5 location=Helper line=957 try sending Message=Message{entityType=Product, entityId=2345} sendMessages=true
  2011-11-12 10:10:08,301 [_xx4yy5zz6] priority=WARN  app_name=test_app thread=ajp-nio-8009-exec-5 location=Helper line=933 send failure
  2011-11-12 10:10:08,201 [_xx4yy5zz6] priority=INFO  app_name=test_app thread=ajp-nio-8009-exec-5 location=Filter line=966 _message=HttpRequestIn dye=_xx4yy5zz6 method=PUT uri=/entity/v1/2345
  
`;


function App() {


  type EditorHandle = React.ElementRef<typeof IgnoreRulesEditor>;

  const editorRef = React.useRef<EditorHandle>(null); // assign null makes it compatible with elements.

  const [goodLogFuzzResult, setGoodLogFuzzResult] = useState<FuzzResult>();

  const [badLogFuzzResult, setBadLogFuzzResult] = useState<FuzzResult>();

  const goodLogTextRef = React.createRef<HTMLInputElement>();
  const badLogTextRef = React.createRef<HTMLInputElement>();

  const compareGoodVsBad = () => {

    let goodLog = goodLogTextRef.current.value
    let badLog = badLogTextRef.current.value

    setGoodLogFuzzResult(editorRef.current.fuzz(goodLog))
    setBadLogFuzzResult(editorRef.current.fuzz(badLog))

  }

  React.useEffect(() => {
    _.delay(compareGoodVsBad, 500)
  }, []);

  return (
    <div style={{ width: '100%', padding: "5px" }} >

      <h1 style={{ textAlign: "center" }} >Log Diff Viewer</h1>
      <h4 style={{ textAlign: "center" }} >View the real differences between logs with text ignore rules</h4>

      <Grid container spacing={2} xs={12} alignItems="center" justify="center"
      >
        <Grid item xs={6} >
          <TextField
            label="Normal Log"
            style={{ margin: 8 }}
            placeholder="Paste your raw logs here"
            // helperText="Paste your raw logs"
            fullWidth
            multiline
            rowsMax="10"
            rows="10"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            defaultValue={originalGood}
            inputRef={goodLogTextRef}
          />

        </Grid>
        <Grid item xs={6} >
          <TextField
            label="Suspect Log"
            style={{ margin: 8 }}
            placeholder="Paste your raw logs here"
            // helperText="Paste your raw logs"
            fullWidth
            multiline
            rowsMax="10"
            rows="10"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            defaultValue={originalBad}
            inputRef={badLogTextRef}
          />
        </Grid>

        <Grid item xs={4} >
          <Button variant="contained" color="primary" size="medium" fullWidth={true}
            onClick={compareGoodVsBad}
            startIcon={<CompareArrowsIcon />}

          >Compare</Button>
        </Grid>

        <Grid item xs={8} >
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
            >
              <Typography>Text Ignore Rules: (Regular Expression)</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div
                style={{ width: "100%" }}
              >
                <IgnoreRulesEditor ref={editorRef} sampleLog={
                  () => goodLogTextRef.current ?
                    goodLogTextRef.current.value.slice(0, 1000)
                    : ""
                } />
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>



        <Grid item xs={12} >
          <div style={{
            maxWidth: "100%", display: "inline-block",
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>

            {goodLogFuzzResult && badLogFuzzResult && <DiffViewer
              oldValue={goodLogFuzzResult.textForCompare}
              newValue={badLogFuzzResult.textForCompare}

              splitView={true}
              postDiffCallback={
                (diff: ComputedLineInformation) => {

                  console.log("before removeFuzz", diff)

                  diff = JSON.parse(JSON.stringify(diff))

                  //left 
                  {
                    let index = 0
                    const values = goodLogFuzzResult.placeHolderValues;
                    diff.lineInformation.forEach((e: LineInformation) => {
                      const part = e.left;

                      if (part.value) {
                        index = removeFuzz(part, values, index);
                      }
                    }
                    )
                  }

                  //console.log("after left removeFuzz", JSON.parse(JSON.stringify(diff)))

                  //right
                  {
                    let index = 0
                    const values = badLogFuzzResult.placeHolderValues;
                    diff.lineInformation.forEach((e: LineInformation) => {
                      const part = e.right;

                      if (part.value) {
                        index = removeFuzz(part, values, index);
                      }
                    }
                    )
                  }

                  //console.log("after right removeFuzz", JSON.parse(JSON.stringify(diff)))

                  return diff
                }
              } />
            }
          </div>
        </Grid>
      </Grid>







    </div>
  );


}

export default App;
