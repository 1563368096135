
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState, useEffect } from 'react';
import { fuzz, FuzzResult, getIgnoreRegexp, IgnoreRule } from './CompareEngine';

const defaultIgnoreRules: IgnoreRule[] =
    [
        {
            //TODO line=xxx is not specially handled

            expression: /(?<!priority|domain|location|app_name|operation|method|status)=\S*/g.source,
            enabled: true,
            name: "unimportant =value",
            error: false,
        }
        ,
        {
            expression: /[_\-A-Za-z0-9]*\d+[^ ]*/g.source,
            enabled: true,
            name: "ids with number",
            error: false,
        }
    ]
    ;

type EditorProps = {
    // children: ReactNode,
    // ignoreRules: IgnoreRule[],
    sampleLog: () => string,
}

type EditorHandler = {
    getIgnoreRules: () => IgnoreRule[],
    fuzz: (text: string) => FuzzResult,
}

function validateRules(values: IgnoreRule[], changedIndex: number | null = null) : string {
    //console.log("xxx", values, changedIndex)
    let errorMessage = ""
    for (let index = 0; index < values.length; index++) {
        const rule = values[index];

        //empty expression should not be enabled
        if (!rule.expression || rule.expression.length == 0) {
            rule.enabled = false;
            continue;
        }

        if (changedIndex === null || changedIndex == index) {
            try {
                new RegExp(rule.expression, 'g');
                rule.error = false;
            } catch (e) {
                console.log(e);
                rule.error = true;
                rule.enabled = false
                errorMessage = e.message
            }

        };
    }
    return errorMessage;
}

//the following only need execute once
function init(): IgnoreRule[] {
    //console.log("xxx", "init")
    let urlObj = new URL(window.location.href);

    let rulesInUrl = urlObj.searchParams.get("ignoreRules");

    let rules = defaultIgnoreRules

    if (rulesInUrl) {
        try {
            rules = JSON.parse(rulesInUrl);
            validateRules(rules);
        } catch (e) {
            console.log(e)
            window.history.pushState('', '', '')
        }
    }
    return rules;
}

const IgnoreRulesEditor: React.ForwardRefRenderFunction<EditorHandler, EditorProps> = (props, ref) => {

    useEffect(() => {
        setIgnoreRules(init())
      }, []);

    const [ignoreRules, setIgnoreRules] = useState<IgnoreRule[]>([])
    const [errorBarShowed, setErrorBarShowed] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")

    React.useImperativeHandle(ref, () => ({
        getIgnoreRules: () => {
            return ignoreRules
        },
        fuzz: (text: string) => {
            return fuzz(text, getIgnoreRegexp(ignoreRules))
        }
    }));

    function updateRules(values: IgnoreRule[], changedIndex: number | null = null) {

        let validateResult = validateRules(values, changedIndex);

        if (validateResult.length > 0) {
            setErrorBarShowed(true);
            setErrorMessage(validateResult);
        }

        setIgnoreRules(values);

        window.history.pushState('', '', "?ignoreRules=" + encodeURIComponent(JSON.stringify(values)));

    }

    const handleRuleChange = (i: number, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let values = [...ignoreRules];

        if ('name' == event.currentTarget.name) {
            values[i].name = event.target.value;
        } else if ('expression' == event.currentTarget.name) {
            values[i].expression = event.target.value;
        }
        updateRules(values, i)
    }

    const addRuleClick = () => {
        const values = [...ignoreRules, {
            name: "rule_" + (ignoreRules.length + 1),
            expression: "blingbling",
            enabled: true,
            error: false,
        }];
        updateRules(values, ignoreRules.length - 1)
    }

    const removeRuleClick = (i: number) => {
        let values = [...ignoreRules];
        values.splice(i, 1);
        updateRules(values)
    }

    const enableRule = (i: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const enabled = event.target.checked;
        let values = [...ignoreRules];
        values[i].enabled = enabled;
        updateRules(values)
    }

    //const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    // console.log("xxx", props.sampleLog())

    const handleErrorBarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorBarShowed(false);
    };

    return <div>
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={errorBarShowed}
            autoHideDuration={6000}
            onClose={handleErrorBarClose}
            message={errorMessage}
            action={
                <React.Fragment>

                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleErrorBarClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
        
        {ignoreRules.map((el, i) =>
            <div key={i} style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid",
                borderColor: "rgba(0, 0, 255, 0.2)",
                margin: "2px",
                padding: "1px",
            }}>
                <IconButton size="medium" color="primary"
                    onClick={() => removeRuleClick(i)}
                ><DeleteIcon /></IconButton>

                <TextField variant="filled"
                    label={`rule name:`}
                    name={"name"}
                    defaultValue={el.name || ''}
                    onChange={(event) => handleRuleChange(i, event)} />


                <Tooltip
                    title={
                        <React.Fragment>
                            <big>{"Enabled?"}</big>
                        </React.Fragment>
                    }
                >
                    <Switch
                        color="primary"
                        size="medium"
                        checked={el.enabled}
                        onChange={(event) => enableRule(i, event)}
                    />
                </Tooltip>


                <TextField variant="filled" fullWidth={true}
                    label={`expression:`}
                    name={"expression"}
                    error={el.error}
                    defaultValue={el.expression || ''}
                    onChange={(event) => handleRuleChange(i, event)} />

                {/* https://regexr.com/?expression=abc&text=abc */}

                <Tooltip
                    title={
                        <React.Fragment>
                            <big>{"Edit in Regex"}</big>
                        </React.Fragment>
                    }
                >
                    <IconButton onClick={() => {
                        window.open(
                            `https://regexr.com/?expression=${encodeURIComponent(el.expression)}&text=${encodeURIComponent(props.sampleLog())}`, "_blank");
                    }}>
                        <OpenInNewIcon />
                    </IconButton>

                </Tooltip>

            </div>
        )
        }
        <IconButton size="medium" color="primary"
            onClick={() => addRuleClick()}
        ><AddCircleIcon />
        </IconButton>

    </div>;
}


export default React.forwardRef(IgnoreRulesEditor)


